<template>
  <div class="spent">
    <div class="title-section">
      <h1 class="title mb-0">
        LTV - CPA (paid)
      </h1>
      <div class="title-right">
        <a
          href="https://octopus.tinylab.io/dashboard/"
          class="mr-4"
          target="_blank"
        >
          <label class="button is-primary is-outlined is-small">
            Octopus 🐙
          </label>
        </a>
        <last-update dashboard-name="LTV CPA Paid" />
      </div>
    </div>

    <filters
      :allow-granularity="false"
      :variant-options="variantOptions"
    />
    <div class="ltv-filters" />
    <section>
      <grid
        :columns="columns"
        :default-hidden-columns="defaultHiddenColumns"
        :data="ltv"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        :show-footer="false"
        :side-bar="sidebar"
        :group-default-expanded="1"
        @ready="api = $event"
        @visibility="visibility = $event"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import { GET_LTV_PAID } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import LastUpdate from '../components/LastUpdate';

export default {
  components: { LastUpdate },
  mixins: [GridMixin],
  data() {
    return {
      variantOptions: {
        title: 'Breakdown',
        list: [
          {
            key: 'product',
            name: 'Products',
          },
          {
            key: 'country',
            name: 'Country',
          },
          {
            key: 'lunigroup',
            name: 'Luni Group',
          },
        ],
      },
      ltvDistance: '3y',
      ltvCurrency: 'USD',
      rawKpis: {},
      ltvPaidByApp: [],
      ltvObject: {},
      countries,
      api: null,
      visibility: false,
      sidebar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
            },
            minWidth: 225,
            width: 225,
            maxWidth: 225,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: this.isVariantProduct ? 'productName' : 'country',
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
        },
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantProduct() {
      return this.getVariant === 'product';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = 'YYYYMM';
      const labelFormat = 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    defaultHiddenColumns() {
      const columns = [];
      this.timePeriods.forEach((period) => {
        columns.push(`${period.field}.ltv3yLow`, `${period.field}.ltv3yUp`, `${period.field}.initialLtvLow`, `${period.field}.initialLtvUp`);
      });

      return columns;
    },
    columns() {
      const columns = [
        {
          field: 'appName',
          colId: 'application',
          headerName: 'App',
          filter: true,
          pinned: 'left',
          filterParams: {
            suppressSorting: true,
            debounceMs: 200,
          },
          rowGroup: true,
          hide: true,
        },
      ];

      columns.push({
        field: 'country',
        colId: 'country',
        headerName: 'Country',
        filter: true,
        rowGroup: this.isVariantProduct,
        pinned: 'left',
        maxWidth: 150,
        hide: true,
      });

      if (this.isVariantProduct) {
        columns.push({
          field: 'productName',
          colId: 'productName',
          headerName: 'Product',
          hide: true,
        });
      }

      this.timePeriods.forEach((period) => {
        columns.push({
          headerName: period.label,
          colId: period.label,
          children: [
            {
              headerName: 'LTV 3Y',
              children: [
                {
                  headerName: 'Low',
                  field: `${period.field}.ltv3yLow`,
                  colId: `${period.field}.ltv3yLow`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  hide: !this.visibility,
                  width: 100,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                  cellStyle: () => ({ color: 'orange' }),
                },
                {
                  headerName: 'Projected',
                  field: `${period.field}.ltv3y`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  width: 120,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                },
                {
                  headerName: 'Up',
                  field: `${period.field}.ltv3yUp`,
                  colId: `${period.field}.ltv3yUp`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  hide: !this.visibility,
                  width: 100,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                  cellStyle: () => ({ color: 'green' }),
                },
                {
                  headerName: 'Variation vs previous cohort',
                  field: `${period.field}.momEvol`,
                  colId: `${period.field}.momEvol`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  hide: !this.visibility,
                  width: 100,
                  valueFormatter: (val) => this.formatCompletion(val.value),
                  cellStyle: (params) => this.cellStyle(params),
                },
              ],
            },
            {
              headerName: 'Initial LTV',
              field: `${period.field}.initialLtv`,
              children: [
                {
                  headerName: 'Low',
                  field: `${period.field}.initialLtvLow`,
                  colId: `${period.field}.initialLtvLow`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  hide: !this.visibility,
                  width: 100,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                  cellStyle: () => ({ color: 'orange' }),
                },
                {
                  headerName: 'Projected',
                  field: `${period.field}.initialLtv`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  width: 120,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                },
                {
                  headerName: 'Up',
                  field: `${period.field}.initialLtvUp`,
                  colId: `${period.field}.initialLtvUp`,
                  type: 'numericColumn',
                  aggFunc: this.myAvg,
                  resizable: true,
                  hide: !this.visibility,
                  width: 100,
                  valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
                  cellStyle: () => ({ color: 'green' }),
                },
              ],
            },
            {
              headerName: 'NSB',
              field: `${period.field}.nbNsb`,
              type: 'numericColumn',
              aggFunc: this.myAvg,
              resizable: true,
              width: 110,
              valueFormatter: (val) => this.formatKpi(val.value),
              cellStyle: () => ({ color: 'gray' }),
            },
            {
              headerName: 'CPA',
              field: `${period.field}.cpa`,
              type: 'numericColumn',
              aggFunc: this.myAvg,
              resizable: true,
              width: 110,
              valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
            },
            {
              headerName: 'Breakeven',
              field: `${period.field}.breakeven`,
              type: 'numericColumn',
              aggFunc: this.myAvg,
              resizable: true,
              width: 110,
              valueFormatter: (val) => this.formatKpi(val.value, 2, ' €'),
            },
          ],
        });
      });

      return columns;
    },
    ltv() {
      return Object.values(this.rawKpis).map((item) => ({ ...item }));
    },
    dataChange() {
      const { ltvPaidByApp } = this;
      if (ltvPaidByApp.length) {
        return {
          ltvPaidByApp,
        };
      }
      return null;
    },
  },
  watch: {
    dataChange: {
      handler(val) {
        if (val) {
          this.formatLTVAgGrid();
        }
      },
      deep: true,
    },
    ltvDistance() {
      this.api.redrawRows();
    },
    ltvCurrency() {
      this.api.redrawRows();
    },
  },
  methods: {
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    formatDate(year, month, subMonths) {
      const date = new Date(year, month - subMonths - 1);

      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}`;
    },
    formatKpi(rawValue, precision = 0, suffix = '') {
      if (!rawValue) {
        return undefined;
      }

      const amount = rawValue
        .toFixed(precision)
        .replace(/\.0+$/, '')
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${amount} ${suffix}`.trim();
    },
    cellStyle(params) {
      if (params.value < 0) {
        return { color: 'red' };
      }
      if (params.value > 0) {
        return { color: 'green' };
      }
      return { color: 'orange' };
    },
    myAvg(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;
      const [month, property] = field.split('.');

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        return this.aggregationKpis?.[app]?.[kpi]?.[month]?.[property];
      }

      if (kpi) {
        if (this.aggregationKpis?.[kpi]?.All) {
          return this.aggregationKpis?.[kpi]?.All?.[month]?.[property];
        }
        return this.aggregationKpis?.[kpi]?.[month]?.[property];
      }

      return undefined;
    },
    formatLTVAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.ltvPaidByApp
        .forEach((entry) => {
          const {
            appName, country, productName, year, month, nbNsb,
            initialLtvLow, initialLtv, initialLtvUp,
            ltv3yLow, ltv3y, ltv3yUp,
            momEvol, cpa, breakeven,
          } = entry;

          const key = `${appName}-${country}-${productName}`;
          const date = this.formatDate(year, month, 0);

          if (country !== 'All' && productName !== 'All') {
            if (!rowData[key]) {
              rowData[key] = {
                appName,
                country,
                productName,
              };
            }

            if (!rowData[key][date]) {
              rowData[key][date] = {
                nbNsb,
                initialLtvLow,
                initialLtv,
                initialLtvUp,
                ltv3yLow,
                ltv3y,
                ltv3yUp,
                momEvol,
                cpa,
                breakeven,
              };
            }
          } else {
            if (!aggregationData[appName]) {
              aggregationData[appName] = {};
            }

            if (productName === null) {
              aggregationData[appName][date] = {
                nbNsb,
                initialLtvLow,
                initialLtv,
                initialLtvUp,
                ltv3yLow,
                ltv3y,
                ltv3yUp,
                momEvol,
                cpa,
                breakeven,
              };
            } else {
              if (!aggregationData[appName][country]) {
                aggregationData[appName][country] = {};
              }

              aggregationData[appName][country][date] = {
                appName,
                country,
                productName,
                date,
                nbNsb,
                initialLtvLow,
                initialLtv,
                initialLtvUp,
                ltv3yLow,
                ltv3y,
                ltv3yUp,
                momEvol,
                cpa,
                breakeven,
              };
            }
          }
        });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    ltvPaidByApp: {
      query: GET_LTV_PAID,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          isWeekly: false,
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup || this.isVariantProduct,
          isVariantProduct: this.isVariantProduct,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.ltv-filters {
  display: flex;

  .ltv-currency {
    margin-right: 30px;
  }
}

.export {
  margin-left: 2rem;
}

</style>
